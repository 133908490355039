<template>
  <van-form
    :class="{
      'van-form__text-color': !isAdd
    }"
    :show-error-message="false"
    label-width="22vw"
    @submit="onSubmit"
  >
    <van-field
      v-if="isAdd"
      :required="isAdd"
      :rules="[{ required: isAdd }]"
      label="接收意见"
      name="passStatus"
    >
      <template #input>
        <van-radio-group
          v-model="receiveInfo.passStatus"
          :disabled="!isAdd"
          direction="horizontal"
        >
          <van-radio
            v-for="item in takeInTypes"
            :key="item.value"
            :name="item.value"
            >{{ item.label }}</van-radio
          >
        </van-radio-group>
      </template>
    </van-field>
    <template v-if="+receiveInfo.passStatus === 1">
      <van-field
        v-model="receiveInfo.suggest"
        :required="isAdd"
        :readonly="!isAdd"
        :rules="[{ required: isAdd, message: '请输入控制措施' }]"
        autosize
        label="控制措施"
        maxlength="500"
        name="suggest"
        :placeholder="placeholder()"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('suggest')"
      />
      <van-field
        v-model="receiveInfo.measure"
        :required="isAdd"
        :readonly="!isAdd"
        :rules="[{ required: isAdd, message: '请输入整改措施' }]"
        autosize
        label="整改措施"
        maxlength="500"
        name="measure"
        :placeholder="placeholder()"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('measure')"
      />
      <van-field
        v-model="receiveInfo.reasonAnalysis"
        autosize
        label="原因分析"
        :readonly="!isAdd"
        maxlength="500"
        name="reasonAnalysis"
        :placeholder="placeholder()"
        rows="2"
        show-word-limit
        type="textarea"
        @input="handleInput('reasonAnalysis')"
      />
      <van-field
        :required="isAdd"
        :rules="[{ required: isAdd, message: '请输入整改措施' }]"
        :value="receiveInfo.leaderName"
        :clickable="isAdd"
        label="整改人"
        name="leader"
        :placeholder="placeholder('choose')"
        readonly
        @click="onTypesClick($event, 'leader')"
      />
      <van-field
        v-model="receiveInfo.budget"
        :formatter="formatter"
        format-trigger="onBlur"
        label="整改资金"
        :readonly="!isAdd"
        maxlength="8"
        type="number"
        :placeholder="placeholder()"
      >
        <template #button>
          <span>元</span>
        </template>
      </van-field>
      <van-field
        class="details-page__last-from-item"
        label="整改方案"
        name="uploader"
      >
        <template #input>
          <image-uploader
            ref="image-uploader"
            v-model="receiveInfo.fileList"
            :max-count="5"
            :deletable="isAdd"
            :disabled="!isAdd"
            @change="onImageChange"
          />
        </template>
      </van-field>
    </template>
    <template v-else-if="+receiveInfo.passStatus === 0">
      <van-field
        v-model="receiveInfo.content"
        :disabled="!isAdd"
        :show-word-limit="isAdd"
        autosize
        label="拒收原因"
        maxlength="500"
        :placeholder="placeholder()"
        rows="2"
        type="textarea"
        @input="handleInput('content')"
      />
    </template>
    <template v-else>
      <van-field
        :rules="[{ required: true, message: '请选择所属部门' }]"
        :value="receiveInfo.departmentName"
        clickable
        label="所属部门"
        name="department"
        placeholder="请选择所属部门"
        readonly
        required
        @click="departmentVisible = true"
      />
      <van-field
        :required="isAdd"
        :rules="[{ required: isAdd, message: '请选择下发接收人' }]"
        :value="receiveInfo.receiversName"
        :clickable="isAdd"
        label="下级接收人"
        name="receivers"
        :placeholder="placeholder('choose')"
        readonly
        @click="onTypesClick2($event, 'receivers')"
      />
    </template>
    <div v-if="isAdd" class="details-page__footer-button-wrapper--fixed">
      <van-button
        :disabled="loading.status"
        :loading="loading.status"
        :loading-text="loading.text"
        block
        native-type="submit"
        round
        type="info"
        >提交</van-button
      >
    </div>
    <select-person
      key="leader"
      title="整改人"
      :visible="selectPersonVisible"
      :choose-value="receiveInfo.leader"
      position="bottom"
      :multiple="false"
      @close="selectPersonVisible = false"
      @confirm="onPopupConfirm"
    />
    <select-person
      key="receivers"
      title="下发接收人"
      :visible="selectPersonVisible2"
      :choose-value="receiveInfo.receivers"
      position="bottom"
      :multiple="false"
      @close="selectPersonVisible2 = false"
      @confirm="onPopupConfirm2"
    />
    <cascade-department
      ref="department"
      v-model="receiveInfo.department"
      :visible="departmentVisible"
      disabled-key="--"
      :filter-id="receiveInfo.department"
      @close="departmentVisible = false"
      @confirm="onTreeConfirm"
    />
  </van-form>
</template>

<script>
import { takeInTypes } from "./utils/constant";
import { filterEmoji, operateMessage } from "@/utils";
import { loading } from "@/utils/constant";
import {
  receiveTrouble,
  refuseApproves,
  getTroubleReceivesInfoById,
  distributeTrouble
} from "@/api/improve/improve";
import ImageUploader from "@/views/trouble/trouble-fix/components/ImageUploader";
import SelectPerson from "@/components/SelectPerson";
import CascadeDepartment from "@/components/CascadeDepartment";

export default {
  components: { CascadeDepartment, SelectPerson, ImageUploader },
  props: ["id"],
  data() {
    return {
      isAdd: 5,
      takeInTypes,
      loading: { ...loading },
      selectPersonVisible: false,
      selectPersonVisible2: false,
      departmentVisible: false,
      receiveInfo: {
        passStatus: "1",
        budget: "",
        measure: "",
        content: "",
        suggest: "",
        reasonAnalysis: "",
        leader: "",
        leaderName: "",
        // 后端接收的
        files: [],
        // 后端返回的
        disposesTreatmentFiles: [],
        fileList: [],
        // 下发需要的字段
        department: "",
        departmentName: "",
        troubleId: "",
        receivers: "",
        receiversName: ""
      },
      personConf: { label: "", value: "" }
    };
  },
  computed: {
    troubleInfo() {
      return this.detailsComponents.troubleInfo;
    }
  },
  inject: {
    detailsComponents: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    "receiveInfo.passStatus"(val) {
      const blackList = [
        "troubleId",
        "passStatus",
        "content",
        "receivers",
        "department",
        "receiversName"
      ];
      if (+val === 0) {
        Object.keys(this.receiveInfo).forEach(key => {
          if (!blackList.includes(key)) {
            this.receiveInfo[key] = "";
          }
        });
        // 拒收
        this.receiveInfo.files = [];
        this.receiveInfo.fileList = [];
      } else if (+val === 1) {
        this.receiveInfo.content = "";
        this.receiveInfo.files = [];
        this.receiveInfo.fileList = [];
      }
    },
    troubleInfo(val) {
      if (val) {
        this.setInfo();
      }
    }
  },
  created() {
    this.isAdd = JSON.parse(this.$route.query.add);
    this.getToDisk();
    if (!this.isAdd) {
      this.getInfo();
    } else {
      this.setInfo();
    }
  },
  methods: {
    saveToDisk() {
      sessionStorage.setItem(
        "trouble-receive-person",
        JSON.stringify(this.personConf)
      );
    },
    getToDisk() {
      try {
        this.personConf = JSON.parse(
          sessionStorage.getItem("trouble-receive-person")
        ) || { label: "", value: "" };
      } catch (e) {
        console.log(e);
        this.personConf = { label: "", value: "" };
      }
    },
    onTreeConfirm({ label }) {
      this.receiveInfo.departmentName = label;
    },
    setInfo() {
      const {
        troubleLevel,
        troubleLevelName,
        measure,
        suggest,
        gist,
        department,
        id,
        departmentName
      } = this.troubleInfo;
      this.receiveInfo.troubleLevel = troubleLevel;
      this.receiveInfo.troubleLevelName = troubleLevelName;
      this.receiveInfo.measure = measure;
      this.receiveInfo.suggest = suggest;
      this.receiveInfo.gist = gist;
      this.receiveInfo.troubleId = id;
      this.receiveInfo.department = department;
      this.receiveInfo.departmentName = departmentName;
      this.receiveInfo.receiversName = this.personConf?.label || "";
      this.receiveInfo.receivers = this.personConf?.value || "";
      this.$nextTick(() => {
        this.$refs.department.getDepartmentTreeData();
      });
    },
    placeholder(type = "content") {
      let content = "请输入内容";
      if (type === "time") {
        content = "点击选择时间";
      }
      if (type === "choose") {
        content = "请选择";
      }
      return this.isAdd ? content : "--";
    },
    onImageChange(val) {
      this.receiveInfo.fileList = val;
    },
    async getInfo() {
      try {
        this.receiveInfo = await getTroubleReceivesInfoById(this.id);
        this.formatterGetInfo();
        this.setInfo();
      } catch (e) {
        console.log(e);
      }
    },
    formatterSaveInfo() {
      this.receiveInfo.files =
        this.receiveInfo.fileList &&
        this.receiveInfo.fileList.map(item => item.fileInfo);
      delete this.receiveInfo.fileList;
    },
    formatterGetInfo() {
      const result = this.receiveInfo;
      this.receiveInfo.fileList =
        result.disposesTreatmentFiles &&
        result.disposesTreatmentFiles.map(file => {
          return {
            url: `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=false&fileName=${file.id}`,
            name: file.name,
            fileInfo: file,
            isImage: true
          };
        });
      this.$refs["image-uploader"].setInnerValue(this.receiveInfo.fileList);
    },
    async receiveTrouble() {
      try {
        this.formatterSaveInfo();
        const ret = await receiveTrouble(this.receiveInfo);
        operateMessage(!!ret, "接收");
        if (ret) this.$router.back();
      } catch (e) {
        console.log("receiveTrouble -> e", e);
      }
    },
    async refuseTrouble() {
      try {
        const r = await refuseApproves(this.receiveInfo);
        operateMessage(!!r, "拒绝");
        if (r) this.$router.back();
      } catch (e) {
        console.log("refuseTrouble -> e", e);
      }
    },
    async distributeTrouble() {
      try {
        const r = await distributeTrouble(this.receiveInfo);
        operateMessage(!!r, "下发");
        if (r) this.$router.back();
      } catch (e) {
        console.log(e);
      }
    },
    onSubmit() {
      this.receiveInfo.troubleId = this.id;
      if (+this.receiveInfo.passStatus === 1) {
        this.receiveTrouble();
      } else if (+this.receiveInfo.passStatus === 0) {
        this.refuseTrouble();
      } else {
        this.distributeTrouble();
      }
    },
    handleInput(key) {
      this.receiveInfo[key] = filterEmoji(this.receiveInfo[key]);
    },
    onTypesClick(ev) {
      ev.preventDefault();
      if (!this.isAdd) return;
      this.selectPersonVisible = true;
    },
    onTypesClick2(ev) {
      ev.preventDefault();
      if (!this.isAdd) return;
      this.selectPersonVisible2 = true;
    },
    onPopupConfirm(ids, list) {
      const row = list?.[0] || {};
      this.receiveInfo.leader = row.value || "";
      this.receiveInfo.leaderName = row.label || "";
    },
    onPopupConfirm2(ids, list) {
      const row = list?.[0] || { label: "", value: "" };
      this.receiveInfo.receivers = row.value || "";
      this.receiveInfo.receiversName = row.label || "";
      this.personConf = row;
      this.saveToDisk();
    },
    formatter(value) {
      const dotIndex = value.indexOf(".");
      const sliceLen = dotIndex !== -1 ? dotIndex + 3 : value.length;
      return value.slice(0, sliceLen);
    }
  }
};
</script>

<style></style>
